import OktaSignIn from '@okta/okta-signin-widget';
import { OktaAuth } from '@okta/okta-auth-js';

const oktaSignIn = new OktaSignIn({
  baseUrl: process.env.VUE_APP_BASE_URL_OKTA,
  clientId: process.env.VUE_APP_CLIENT_ID_OKTA,
  redirectUri: process.env.VUE_APP_REDIRECT_OKTA,
  authParams: {
    pkce: true,
    issuer: process.env.VUE_APP_ISSUER_OKTA,
    display: 'page',
    scopes: ['openid', 'profile', 'email'],
  },
});
const oktaAuth = new OktaAuth({
  issuer: process.env.VUE_APP_ISSUER_OKTA,
  clientId: process.env.VUE_APP_CLIENT_ID_OKTA,
  scopes: ['openid', 'email'],
  redirectUri: process.env.VUE_APP_REDIRECT_OKTA,
  postLogoutRedirectUri: 'https://aem.one-recette.cultura.com',
});

export { oktaAuth, oktaSignIn };
