<script setup>
import {onBeforeMount, ref} from 'vue';
import LP from '@/services/axios/loyaltyportal';
import store from '@/store';
import { closeHandler } from '@/methods/utils';
import TC_Wrapper from "vue-tag-commander";

const props = defineProps(['gift']);
const emitSelf = defineEmits(['purchaseConfirm', 'close']);
const error = ref({
    active: false,
    message: 'Un problème est survenu, veuillez réessayer',
});
const loading = ref(false);
const isForDonation = props.gift.extra_info.don[0]?.toLowerCase() === 'oui';

document.body.style.overflow = 'hidden';

function closing() {
    document.body.style.overflow = 'auto';
    emitSelf('close', false);
}

onBeforeMount(async () => {
    const wrapper = TC_Wrapper.getInstance();
    let pageName = store.state.modalActive.from + ' / ' + props.gift.title.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") + ' / obtenir ce code'
    if(store.state.modalActive.from === 'boutique cadeau' ) {
        pageName = props.gift.title.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") + ' / obtenir ce cadeau';
    }
    else if(store.state.modalActive.from === 'dons') {
        pageName = props.gift.title.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") + ' / faire un don / confirmation ';
    }
    await wrapper.setTcVars({"page_cat1": store.state.modalActive.from, "page_name": pageName});
});
const purchaseAdvantage = () => {
    if (error.value.active) {
        loading.value = false;
        error.value.active = false;
    } else {
        loading.value = true;
        // Check if store.state.loyaltyportal.user already get a burn with id 23 Loi Laang
        const currentYear = new Date().getFullYear();
        const getAllBurnWithLimited = store.state.loyaltyportal.user.burns.filter((burn) => burn.gift_id === 23 && new Date(burn.created).getFullYear() === currentYear);
        if (props.gift.id === 23 && getAllBurnWithLimited.length >= 2) {
            loading.value = false;
            error.value.active = true;
            error.value.message = 'Vous avez déjà atteint la limite de commande ce cadeau';
            return;
        }

        LP.post(`/loyalty_portal/${isForDonation ? 'donation/' : 'burn/gift/'}`, {
            idGift: props.gift.id,
            fidAccount: localStorage.getItem('user_id'),
            ...(isForDonation ? {} : { access_token: localStorage.getItem('accessToken_cultura') }),
        }).then(
            () => {
                LP.get('/resources/fid-account/get-info/', {
                    params: {
                        fid_account_id: localStorage.getItem('user_id'),
                    },
                }).then(
                    (res) => {
                        store.commit('updateUserInfos', res);
                        loading.value = false;
                        emitSelf('purchaseConfirm', true);
                    },
                ).catch(
                    () => {
                        error.value.active = true;
                        loading.value = false;
                    },
                );
            },
        ).catch(
            () => {
                error.value.active = true;
                loading.value = false;
            },
        );
    }
};
</script>

<template>
    <div
        class="modalContent"
        @click="closeHandler"
    >
        <div class="popin_box popin_confirmationPoints">
            <p
                v-if="!error.active && !loading"
                class="txt"
            >
                Vous y êtes presque...
            </p>
            <h3>
                <template v-if="!error.active && !loading">
                    <span v-if="parseInt(props.gift.points_to_gift)> 0">Prêt à transformer {{ parseInt(props.gift.points_to_gift).toLocaleString() }} points<br> en <span class="colorBlueCulturaddict"> {{ props.gift.title }} </span> ?</span>
                    <span v-else>Prêt à obtenir votre Cadeau <span class="color-pink"> {{ props.gift.title }} </span> ?</span>
                </template>
                <template v-else-if="error.active && !loading">
                    {{ error.message }}
                </template>
                <template v-else>
                    <div class="loading">
                        <span class="dot dot1" />
                        <span class="dot dot2" />
                        <span class="dot dot3" />
                    </div>
                </template>
            </h3>
            <div
                v-if="!error.active && !loading"
                class="imgPopinCtn"
            >
                <img
                    :src="props.gift.url"
                    :alt="props.gift.title"
                >
            </div>
            <p
                v-if="!error.active && !loading"
                class="txt"
            >
                Il ne vous reste plus qu’à confirmer ici :
            </p>
            <div
                v-if="!loading"
                class="ctaCtn"
            >
                <a
                    v-if="!isForDonation"
                    class="cta cta--tertiary"
                    @click="purchaseAdvantage()"
                >
                    {{ error.active ? 'Réessayer' : ' Oui, obtenir ce Cadeau' }}
                </a>
                <a
                    v-else
                    class="cta cta--tertiary"
                    @click="purchaseAdvantage()"
                >
                    {{ error.active ? 'Réessayer' : ' Oui, faire un don' }}
                </a>
                <a
                    :to="{name: 'advantages'}"
                    class="cta cta--secondary"
                    @click="closing"
                >
                    {{ error.active ? 'Annuler' : 'Non, réfléchir encore' }}
                </a>
            </div>
            <span
                class="closeBtn"
                @click="closing"
            />
        </div>
    </div>
</template>
