import { createStore } from 'vuex';

// Create a new store instance.
const store = createStore({
  state: {
    modalActive: {
      name: null,
      lastBurn: null,
      from: null,
    },
    isMobile: window.innerWidth <= 1024,
    currentHeight: window.innerHeight,
    currentWidth: window.innerWidth,
    isTouchDevice: 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0,
    loyaltyportal: {
      lastBurn: null,
      token: null,
      userIsActive: null,
      parameters: {},
      gifts: [],
      user: {
        isReady: false,
        burns: [],
        events: [],
        earns: [],
        bills: [],
        metrics: {},
        current_balance: 0,
      },
    },
    loyaltyengine: {
      allStore: [],
    },
  },
  mutations: {
    updateUserInfos(state, res) {
      Object.assign(state.loyaltyportal.user, res);
    },
  },
  getters: {
    lastBurn() {
      return store.state.loyaltyportal.user.burns.sort((a, b) => new Date(b.created) - new Date(a.created))[0];
    },
  },
});

export default store;
