import { createApp } from 'vue';
import moment from 'moment';
import App from './App.vue';
import router from './router';
import store from '@/store';
import OktaVue from '@okta/okta-vue'
import { oktaAuth } from './services/okta/config';

const app = createApp(App);

// Sentry.init({
//   app,
//   dsn: '',
//   integrations: [
//     new BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracePropagationTargets: ['localhost', /^\//],
//     }),
//   ],
//   tracesSampleRate: 1.0,
// })

app.use(store);
app.use(router);
app.use(OktaVue, {
  oktaAuth,
  onAuthRequired: () => {
    router.push('/login')
  },
  onAuthResume: () => {
    router.push('/login')
  },
})
app.config.globalProperties.$filters = {
  dateFormat(value, format = 'DD/MM/YYYY') {
    return moment(value).format(format);
  },
  giftTitleFormat(value) {
    return String(value.toLowerCase()).replace(/ /g, '-');
  },
};

app.mount('#app');
