import LP from '@/services/axios/loyaltyportal';
import store from '@/store';
import router from '@/router';

const user = {
  async set(state, user) {
    if (user.token === null || user.id === null) {
      return false;
    }

    LP.defaults.headers.common.Authorization = `Bearer ${user.token}`;

    localStorage.setItem('user_token', user.token);
    localStorage.setItem('user_id', user.id);
    state.loyaltyportal.token = user.token;
    state.loyaltyportal.user.id = user.id;
    return await this.get(state);
  },
  async get(state) {
    const config = { params: { fid_account_id: state.loyaltyportal.user.id } };
    const getFidAccountInfo = await LP.get('/resources/fid-account/get-info/', config)
      .catch(
        (error) => {
          if (error.response?.status === 404) {
            state.loyaltyportal.userIsActive = false;
          }
          console.log(error);
          if (error.response?.status === 401) {
            state.loyaltyportal.user.isReady = false;
            localStorage.removeItem('user_token');
            localStorage.removeItem('user_id');
            localStorage.removeItem('accessToken_cultura');
            localStorage.removeItem('uuidCultura');
            localStorage.removeItem('last_name');
            localStorage.removeItem('first_name');
            router.push({ name: 'authentication' });
          }
        },
      );
    state.loyaltyportal.user.isReady = true;
    state.loyaltyportal.user = { ...store.state.loyaltyportal.user, ...getFidAccountInfo };
    state.loyaltyportal.user.last_name = localStorage.getItem('last_name');
    state.loyaltyportal.user.first_name = localStorage.getItem('first_name');

    const isAssociate = state.loyaltyportal.user.extra_info?.typecontact?.includes('collaborateur');
    // get parameters
    const getParameters = await LP.get('/loyalty_portal/parameters/');

    state.loyaltyportal.parameters = getParameters.results.filter((param) => {
      // if there is no contact type
      if (!param.extra_info || !param.extra_info.typecontact || !param.extra_info.typecontact.length) {
        return true;
      }

      // if the contact type is associate
      if (param.extra_info.typecontact.includes('collaborateur') && isAssociate) {
        return true;
      }

      // if the contact is not associate
      if (param.extra_info.typecontact.includes('particulier') && !isAssociate) {
        return true;
      }

      return false;
    });

    return getFidAccountInfo;
  },
};

export default user;
