<script setup>

</script>

<template>
  <footer>
    <div class="footerCtn">
      <ul>
        <li>
          <a
            href="https://www.cultura.com/"
            target="_blank"
            class="cta cta--quaternery"
          >Cultura.com</a>
        </li>
        <li>
          <a
            href="https://www.cultura.com/mentions-legales/cultura-cgv/cgu-culturaddict.html"
            target="_blank"
          >CGU Cultur'addict</a>
        </li>
        <li>
          <a
            href="https://www.cultura.com/mentions-legales/protection-des-donnees-personnelles.html"
            target="_blank"
          >Données personnelles</a>
        </li>
        <div>
          <a
            href="https://www.cultura.com/mentions-legales/cultura-cgv/cgu-culturaddict.html"
            target="_blank"
          >CGU Cultur'addict</a>
          <a
            href="https://www.cultura.com/mentions-legales/protection-des-donnees-personnelles.html"
            target="_blank"
            style="margin-top: 12px;"
          >Données personnelles</a>
        </div>
      </ul>
    </div>
  </footer>
</template>

<style scoped>

</style>
