<script setup>
import moment from 'moment';
import store from '@/store';
import { formatNumber } from '@/methods/utils';

const props = defineProps(['cardOnly']);
</script>

<template>
  <div class="fidelityCardV2">
    <div class="fidelityCardV2__mainBlock">
      <template v-if="store.state.loyaltyportal.user.earns.length === 0">
        <div class="fidelityCardV2__txt">
          Vous n'avez pas encore cumulé de points !
        </div>
        <br>
        <div class="fidelityCardV2__txt">
          <b>Mais pas de panique !</b>
        </div>
        <br>
      </template>
      <template v-else>
        <p class="fidelityCardV2__txt">
          <span>
            Vous êtes à
          </span>
        </p>
        <p class="fidelityCardV2__points">
          {{ parseInt(store.state.loyaltyportal.user.current_balance) || 0 }}
        </p>
        <p class="fidelityCardV2__txt">
          points
        </p>
      </template>
    </div>
    <p
      v-if="store.state.loyaltyportal.user.expiring_points"
      class="fidelityCardV2__date"
    >
      <template v-if="store.state.loyaltyportal.user.expiring_points">
        <template v-if="moment(store.state.loyaltyportal.user.expiring_points.validity_end_date).add(6, 'M').isAfter(moment())">
          <span class="fidelityCardV2__date__expiringPoints"> {{ parseInt(store.state.loyaltyportal.user.expiring_points.total_points).toLocaleString() }} points </span>
          valables jusqu'au<br>
          <span class="fidelityCardV2__date__limitDate">{{ $filters.dateFormat(store.state.loyaltyportal.user.expiring_points.validity_end_date) }}</span>
        </template>
      </template>
    </p>
    <a
        v-if="(parseInt(store.state.loyaltyportal.user.current_balance) >= 0 && !props.cardOnly) || store.state.loyaltyportal.user.current_balance === null"
      class="cta fidelityCardV2__cta"
      href="https://www.cultura.com/programme-fidelite-culturaddict.html"
      target="_blank"
    >Comment cumuler des points ?</a>
    <router-link
      v-if="parseInt(store.state.loyaltyportal.user.current_balance) < 0 && !props.cardOnly"
      :to="{name: 'my-points'}"
      class="cta fidelityCardV2__cta"
    >
      Voir mon historique de points
    </router-link>
  </div>
</template>

<style>

.fidelityCardV2__mainBlock {
  margin: auto;
  display: flex;
  max-width: 313px;
  padding: 10px 39px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 12px;
  background: #004079;
  text-align: center;
  color: #FFF;
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
}

.fidelityCardV2__points {
  font-size: 60px;
  font-style: normal;
  font-weight: 800;
  line-height: 100%;
}

.fidelityCardV2__date {
  color:  #0170D5;
  font-size: 10px;
  font-style: italic;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.fidelityCardV2__date__expiringPoints, .fidelityCardV2__date__limitDate {
  font-weight: 700;
  line-height: 180%
}

.fidelityCardV2__date__limitDate {
  font-size: 14px;
}

.fidelityCardV2__cta {
  color: #1C2D55;
  background-color: #FFF;
  max-width: 223px;
  margin: auto;
}

</style>
