<script setup>
import { ref, watch } from 'vue';

import { useStore } from 'vuex';
import Nav from '@/components/Commun/Nav.vue';

const store = useStore();
// Mobile detection
const open = ref(!store.state.isMobile);
// If  open is true, hide the scrollbar
watch(store.state, () => {
  open.value = !store.state.isMobile;
});

watch(open, () => {
  if (store.state.isMobile && open.value) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'auto';
  }
});

</script>

<template>
  <header>
    <div class="headerCtn">
      <div class="headerTop">
        &lt; <a
          href="https://www.cultura.com/"
          class="headerTop__link"
        >Retour sur Cultura.com</a>
      </div>

      <div class="headerBottom">
        <div class="headerBottom__left">
          <a
            href="/"
            class="headerBottom__left__link"
          ><span
            class="header_logo"
          />
          </a>
          <a
            href="/"
            class="headerBottom__left__fidelityTxt"
          >
            <p>
              Mon Espace Fidélité
            </p>
          </a>
        </div>

        <div class="headerBottom__right">
          <a
            href="https://www.cultura.com/programme-fidelite-culturaddict/faq.html"
            target="_blank"
            class="headerBottom__right__link"
          >
            <img
              src="/img/picto_question.svg"
              alt=""
              class="headerBottom__right__link__img"
            >
            <span class="headerBottom__right__link__helpTxt hideMobile">Aide</span>
          </a>
          <span
            :class="[!open ? 'headerBottom__right__burger' : 'headerBottom__right__close', 'hideDesktop']"
            @click="open = !open"
          />
        </div>
      </div>
    </div>
  </header>

  <Nav
    :open="open"
    @close-navbar="(n) => open = n"
  />
</template>

<style>
.headerBottom__left__fidelityTxt p {
  padding-bottom: 6px;
}

@media screen and (max-width: 479px) {
  .headerBottom__left__fidelityTxt p {
    padding-bottom: 0px;
  }

  .headerBottom__left__fidelityTxt {
    align-items: center;
  }
}
</style>
