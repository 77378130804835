<script setup>
import { onBeforeMount, ref } from 'vue';
import store from '@/store';
import user from '@/methods/user';

import Header from './components/Commun/Header.vue';
import Footer from './components/Commun/Footer.vue';
import Authentication from '@/views/Authentication.vue';
import DeclarePurchase from '@/components/Modals/DeclarePurchase.vue';
import DeclaredPurchase from '@/components/Modals/DeclaredPurchase.vue';
import ConfirmPurchaseProduct from '@/components/Modals/ConfirmPurchaseProduct.vue';
import ConfirmProduct from '@/components/Modals/ConfirmProduct.vue';
import useAdvantage from '@/components/Modals/useAdvantage.vue';
import LoyaltyCardModal from '@/components/Modals/LoyaltyCardModal.vue';
import authentication from '@/methods/authentication';
import TC_Wrapper from "vue-tag-commander";

const loading = ref(true);
document.querySelector('body').style.paddingBottom = '130px';
const wrapper = TC_Wrapper.getInstance();
onBeforeMount(async () => {
    await wrapper.addContainer('portail-fid', process.env.VUE_APP_TAGCOMMANDER, 'head');
  // verifies that the account is active
  // and in either case we check it by looking in the store.
  user.set(store.state, {
    id: localStorage.getItem('user_id'),
    token: localStorage.getItem('user_token'),
  }).then(async (res) => {
    if (res) {
      store.state.loyaltyportal.userIsActive = store.state.loyaltyportal.user?.is_active === true && store.state.loyaltyportal.user?.is_anonymized === false;
    } loading.value = false;
  }).catch(() => {
    loading.value = false;
  });
});

const returnToPreviousPage = async () => {
  await authentication.logout(store.state);
};

window.addEventListener('resize', () => {
  store.state.isMobile = window.innerWidth <= 1024;
  store.state.currentWidth = window.innerWidth;
  store.state.currentHeight = window.innerHeight;
});

</script>

<template>
  <div>
    <Authentication v-if="!store.state.loyaltyportal.user.isReady && !loading && store.state.loyaltyportal.userIsActive === null" />
    <div
      v-else-if="loading || store.state.loyaltyportal.userIsActive === false"
      id="appLoading"
    >
      <span
        class="header_logo"
      />
      <div
        v-if="loading"
        class="loading"
        style="margin-top: 16px;"
      >
        <span
          style="background-color:white"
          class="dot dot1"
        />
        <span
          style="background-color:white"
          class="dot dot2"
        />
        <span
          style="background-color:white"
          class="dot dot3"
        />
      </div>
      <div v-else>
        <h1>
          Votre programme de fidélité Cultur’addict n’est plus actif sur ce compte Cultura
          <a
            style="max-width: 350px; margin: 12px auto"
            class="cta cta--secondary"
            @click="
              returnToPreviousPage()

            "
          >Retour</a>
        </h1>
      </div>
    </div>
    <div v-else-if="store.state.loyaltyportal.userIsActive">
      <Header />

      <router-view v-slot="{ Component }">
        <transition name="fadeTransition">
          <component :is="Component" />
        </transition>
      </router-view>

      <Transition name="modal">
        <DeclarePurchase
          v-if="'DeclarePurchase' === store.state.modalActive.name"
          @close="store.state.modalActive.name = $event"
          @declared-purchase="store.state.modalActive.name = 'DeclaredPurchase'"
        />
      </Transition>

      <Transition name="modal">
        <DeclaredPurchase
          v-if="'DeclaredPurchase' === store.state.modalActive.name"
          @close="store.state.modalActive.name = $event"
        />
      </Transition>

      <Transition name="modal">
        <ConfirmPurchaseProduct
          v-if="store.state.modalActive.name === 'ConfirmPurchaseProduct'"
          :gift="store.state.modalActive.gift"
          @close="store.state.modalActive.name = $event"
          @purchaseConfirm="store.state.modalActive.name = 'ConfirmProduct'"
        />
      </Transition>

      <Transition name="modal">
        <ConfirmProduct
          v-if="'ConfirmProduct' === store.state.modalActive.name"
          :gift="store.state.modalActive.gift"
          @close="store.state.modalActive.name = $event"
        />
      </Transition>

      <Transition name="modal">
        <useAdvantage
          v-if="'useAdvantage' === store.state.modalActive.name"
          :burn="store.state.modalActive.burn"
          :last-burn="store.state.modalActive.lastBurn"
          @close="store.state.modalActive.name = $event"
        />
      </Transition>

      <Transition name="modal_bottom">
        <LoyaltyCardModal
          v-if="'LoyaltyCard' === store.state.modalActive.name"
          :user="store.state.loyaltyportal.user"
          @close="store.state.modalActive.name = $event"
        />
      </Transition>

      <Footer />
    </div>
  </div>
</template>

<style>

.main {
  min-height: calc(100vh - 228px);
}

#appLoading {
    width: 100vw;
    height: 100vh;
    background: #004079;
    font-family: sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    position: fixed;
}

#appLoading div:nth-child(1) {
  margin: auto;
}

#appLoading h1 {
  color: white;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 130%;
  margin-top: 10px;
}

#appLoading a {
  margin-top: 10px;
}

/*
  --------------------------------------
*/

.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.2s ease-out;
}
.modal-enter-from {
  opacity: 0.8;
}

.modal-leave-to {
  opacity: 0;
}

/*
  --------------------------------------
*/

.modal_bottom-enter-active,
.modal_bottom-leave-active {
  transition: all 0.4s ease;
}

.modal_bottom-enter-from {
  transform: translateY(100%);
}

.modal_bottom-leave-from {
  transform: translateY(0%);
}

.modal_bottom-leave-to {
  transform: translateY(100%);
}

.fadeTransition-enter-from,
.fadeTransition-leave-to {
  opacity: 0;
}

.fadeTransition-enter-active,
.fadeTransition-leave-active {
  transition: opacity 0.1s linear;
}
</style>
