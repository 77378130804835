<script setup>
import { closeHandler } from '@/methods/utils';
const emit = defineEmits(['close']);

document.body.style.overflow = 'hidden';

function closing() {
  document.body.style.overflow = 'auto';
  emit('close', false);
}
</script>

<template>
  <div
    class="modalContent"
    @click="closeHandler"
    @click.once
  >
    <div class="popin_box popin_declarationValidee">
      <h4>C’est tout bon, votre achat a été ajouté. Au revoir joker !</h4>
      <p class="txt">
        Votre solde de points sera mis à jour dans 48h.
      </p>

      <router-link
        :to="{name: 'my-points'}"
        class="cta cta--secondary"
        @click="closing"
      >
        Consulter mon historique de points
      </router-link>
      <span
        class="closeBtn"
        @click="closing"
      />
    </div>
  </div>
</template>
