<script setup>
import { onMounted } from 'vue';
import JsBarcode from 'jsbarcode';
import { closeHandler } from '@/methods/utils';

const props = defineProps(['user']);
const activeCard = props.user.fid_cards.find((c) => c.is_active);
const emit = defineEmits(['close']);

document.body.style.overflow = 'hidden';

function closing() {
  document.body.style.overflow = 'auto';
  emit('close', false);
}
onMounted(() => {
  JsBarcode('.barcode').options({ height: 142, format: 'CODE128' }).init();
});
</script>

<template>
  <div
    class="modal_poppin_bottom"
    @click="closeHandler"
  >
    <div class="popin_bottom">
      <span
        class="closeBtn"
        @click="closing"
      />
      <h4>Ma carte fidélité</h4>
      <p class="popin_bottom_txt card_owner">
        {{ `${props.user.first_name} ${props.user.last_name}` }}
      </p>
      <img
        id="img_culturAddict"
        src="../../../public/img/cultura_bg_fidcard_mobile.png"
        alt="cultur'addict"
        width="269"
        height="181"
      >
      <svg
        id="barcode"
        class="barcode"
        :jsbarcode-format="'auto'"
        :jsbarcode-value="activeCard.fid_code"
        :jsbarcode-displayvalue="false"
      ></svg>
      <p class="popin_bottom_txt card">
        {{ `N° : ${activeCard.fid_code}` }}
      </p>
    </div>
  </div>
</template>

<style>
.modal_poppin_bottom {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
}

.popin_bottom {
  position: fixed;
  background: #fff;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  bottom: 0;
  width: 100%;
  height: 85%;
  margin: auto;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 10;
  padding: 60px 40px;
  box-shadow: 0px 5px 30px rgba(132, 140, 154, 0.2);
}

.popin_bottom h4 {
  margin-bottom: 4px;
}

.popin_bottom .closeBtn {
  position: absolute;
  display: block;
  cursor: pointer;
  width: 20px;
  height: 20px;
  top: 24px;
  right: 24px;
  background-image: url(../../../public/img/picto_close.svg);
}

.popin_bottom .popin_bottom_txt {
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0em;
  color: #1C2D55;
}

.popin_bottom .card_owner {
  font-weight: 700;
}

.popin_bottom .card {
  font-weight: 400;
  margin-bottom: 12px;
}

#img_culturAddict {
  margin: 12px 0px 12px 0px
}

</style>
