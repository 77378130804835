<script setup>
import { computed, onBeforeMount, ref } from 'vue';
import { useRoute } from 'vue-router';

import router from '@/router';
import store from '@/store';
import authentication from '@/methods/authentication';

import FidelityCard from '@/components/Commun/FidelityCard.vue';
import boxfid from '@/methods/boxfid';

// Props & emits of the component
const props = defineProps(['open']);
const emit = defineEmits(['closeNavbar']);
const allGifts = ref([]);
const burns = computed(() => store.state.loyaltyportal.user.burns
  .filter((b) => new Date(b.validity_end_date).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0) && b.status_id === 2));
const filteredBurns = computed(() => burns.value.filter((b) => {
  const gift = allGifts.value.find((g) => g.id === b.gift_id);
  return gift && gift.extra_info.don[0] === 'non';
}));

const routeLoaded = useRoute();

onBeforeMount(async () => {
  allGifts.value = await boxfid.getAllGifts();
});

/*
Emit an event to close the navbar
 */
const closeNavBar = () => {
  if (store.state.isMobile) {
    emit('closeNavbar', false);
  }
};

const logout = () => {
  authentication.logout(store.state, document.location.origin);
};
</script>

<template>
  <Transition name="drop-root">
    <nav v-show="props.open">
      <Transition
        v-if="!store.state.isMobile"
        name="drop-child"
      >
        <FidelityCard v-show="props.open" />
      </Transition>
      <Transition name="drop-child">
        <ul
          v-show="props.open"
          class="menuCtn"
        >
          <div
            v-for="(route, index) in router.options.routes.filter(r => r.hasOwnProperty('meta'))"
            :key="index"
            class="menuItem_container menuItem"
          >
            <span
              v-if="route.name === 'my-advantages' && burns.length"
              class="gift_count"
            >{{ filteredBurns.length }}</span>
            <li
              @click="closeNavBar()"
            >
              <router-link
                v-if="route.name !== 'my-card'"
                :to="route.path"
                class="menuItem__linkItem"
              >
                <span
                  :class="{'menuItem__linkItem__text--orange': routeLoaded.name === route.name}"
                  class="menuItem__linkItem__text"
                >{{ route.meta.navTitle }}
                </span>
                <span class="menuItem__linkItem__arrow" />
              </router-link>
              <a
                v-else
                class="menuItem__linkItem"
                :style="{cursor: 'pointer'}"
                @click="(e) => {
                  e.preventDefault();
                  store.state.modalActive.name = 'LoyaltyCard';
                }"
              >
                <span
                  :class="{'menuItem__linkItem__text--orange': routeLoaded.name === route.name}"
                  class="menuItem__linkItem__text"
                >{{ route.meta.navTitle }}</span>
                <span class="menuItem__linkItem__arrow" />
              </a>
            </li>
          </div>
        </ul>
      </Transition>
      <Transition name="drop-child">
        <div
          v-show="props.open"
          class="navCtas"
        >
          <!-- @TODO: A remettre quand CULTURA aurait mis l'API -->
          <a
            v-if="false"
            class="cta"
            @click="store.state.modalActive.name = 'DeclarePurchase'"
          >Déclarer un achat</a>
          <a
            class="cta cta--secondary"
            @click="logout()"
          >Se déconnecter</a>
        </div>
      </Transition>
    </nav>
  </Transition>
</template>

<style>
.drop-child-enter-active,
.drop-child-leave-active {
    transition: opacity 0.35s ease;
}

.drop-child-enter-from,
.drop-child-leave-to {
    opacity: 0;
}

/*
  --------------------------------------
*/

.drop-root-enter-active,
.drop-root-leave-active {
    transition: all 0.35s ease-out;
}

.drop-root-enter-from,
.drop-root-leave-to {
    height: 0%;
    opacity: 0.6;
}

/*
  --------------------------------------
*/

.menuItem_container {
    position: relative;
}

.gift_count {
  position: absolute;
  height: 18px;
  width: 18px;
  text-align: center;
  border-radius: 9999px;
  border-width: 1px;
  border-color: #004079;
  color: #004079;
  font-size: 10px;
  font-weight: 700;
  line-height: 150%;
  bottom: 39px;
  left: 128px;
}
@media screen and (min-width: 800px) and (max-width: 1025px) {
    .gift_count {
        bottom: 40px;
        left: 128px;
    }
}

@media screen and (min-width: 480px) and (max-width: 799px) {
    .gift_count {
        bottom: 40px;
        left: 128px;
    }
}

@media screen  and (max-width: 479px) {
    .gift_count {
        bottom: 25px;
        left: 110px;
    }
}

</style>
