<script setup>
import { closeHandler } from '@/methods/utils';
import FidelityCard from '@/components/Commun/FidelityCard.vue';
import store from '@/store';
import user from "@/methods/user";
import {onBeforeMount} from "vue";
import TC_Wrapper from "vue-tag-commander";

const props = defineProps(['gift']);

const { lastBurn } = store.getters;
const emit = defineEmits(['close']);

document.body.style.overflow = 'hidden';

onBeforeMount(async () => {
    const wrapper = TC_Wrapper.getInstance();
    let pageName = store.state.modalActive.from + ' / ' + props.gift.title.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") + ' / confirmation'
    if(store.state.modalActive.from === 'boutique cadeau' ) {
        pageName = props.gift.title.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") + ' / obtenir ce cadeau / confirmation';
    }
    if(store.state.modalActive.from === 'dons') {
        pageName = props.gift.title.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") + ' / faire un don';
    }
    await wrapper.setTcVars({"page_cat1": store.state.modalActive.from, "page_name": pageName });
});
function closing() {
  document.body.style.overflow = 'auto';
    user.get(store.state);
  emit('close', false);
}
</script>

<template>
  <div
    class="modalContent"
    @click="closeHandler"
  >
    <div class="popin_box popin_validationPoints">
      <div v-if="props.gift.extra_info.don[0] === 'non'">
        <p class="txt">
          Et voilà !
        </p>
        <h3>Votre cadeau <span class="colorBlueCulturaddict"><br>{{ props.gift.title }}</span><br> est à disposition !</h3>
        <p class="txt">
          Retrouvez-le dans la rubrique "Mes Cadeaux" dès maintenant.
        </p>
      </div>
      <div v-else>
        <p class="txt">
          Merci !
        </p>
        <h3>Votre don a bien été pris en compte !</h3>
      </div>

      <h4>Votre compteur de points a été mis à jour</h4>
      <FidelityCard :card-only="true" />
      <p
        v-if="props.gift.extra_info.don[0]?.toLowerCase() === 'non'"
        class="txt"
      >
        Et maintenant, on fait quoi ?
      </p>
      <div class="ctaCtn">
        <a
          v-if="props.gift.extra_info.don[0].toLowerCase() === 'non'"
          class="cta cta--tertiary"
          @click="store.state.modalActive = {name: 'useAdvantage', burn: {title: gift.title, validity_end_date: gift.end_date, extra_info: gift.extra_info}, lastBurn: lastBurn, from: store.state.modalActive.from }"
        >Utiliser mon Cadeau</a>
        <router-link
          :to="{name: 'advantages'}"
          class="cta cta--secondary"
          @click="closing"
        >
          Voir d’autres Cadeaux
        </router-link>
      </div>
      <span
        class="closeBtn"
        @click="closing"
      />
    </div>
  </div>
</template>
