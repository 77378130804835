<template>
    <div className="main">
    <span
        className="header_logo"
    />
        <div id="okta-signin-container"/>
    </div>
</template>
<script>
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import {oktaAuth, oktaSignIn} from '@/services/okta/config';
import store from '@/store';
import router from '@/router';
import loyaltyportal from '@/services/axios/loyaltyportal';
import user from '@/methods/user';

function getLoyaltyInfo(uuid) {
    loyaltyportal.post('/loyalty_portal/okta-login/', {uuid: uuid}, {headers: {}}).then(
        (r) => {
            user.set(store.state, r).then(() => {
                store.state.loyaltyportal.userIsActive = store.state.loyaltyportal.user?.is_active === true && store.state.loyaltyportal.user?.is_anonymized === false;
                store.state.loyaltyportal.user.isReady = true;
                document.querySelector('body').style.paddingBottom = '130px';
                if (store.state.loyaltyportal.user.userIsActive) {
                    router.replace({path: '/'});
                }
            }).catch((err) => {
                console.error(err.message);
            });
        },
    ).catch((err) => {
        store.state.loyaltyportal.user.isReady = false;
        store.state.loyaltyportal.userIsActive = false;
    })
}

export default {
    name: 'Login',
    computed: {
        store() {
            return store;
        },
    },
    mounted() {
        const showWidget = true
        this.$nextTick(async () => {
            // remove the empty space
            document.querySelector('body').style.paddingBottom = '0px';
            document.querySelector('.main').style.minHeight = 'auto';
            oktaAuth.session.get().then((res) => {
                    if (res.user) {
                        res.user().then((res2) => {
                            oktaAuth.token.getWithoutPrompt({
                                responseType: ['token', 'id_token'],
                                scopes: ['openid', 'profile', 'email'],
                            }).then((res) => {
                                localStorage.setItem('accessToken_cultura', res.tokens.accessToken.accessToken);
                            });
                            localStorage.setItem('first_name', res2.profile.firstName);
                            localStorage.setItem('last_name', res2.profile.lastName);
                            localStorage.setItem('uuidCultura', res2.profile.UUID);
                            getLoyaltyInfo(res2.profile.UUID)
                        })
                    } else {
                        oktaSignIn.showSignInToGetTokens(
                            {el: '#okta-signin-container'},
                        ).then((res) => {
                            oktaSignIn.remove();
                            localStorage.setItem('first_name', res.idToken.claims.firstName);
                            localStorage.setItem('last_name', res.idToken.claims.lastName);
                            localStorage.setItem('accessToken_cultura', res.accessToken.accessToken);
                            localStorage.setItem('uuidCultura', res.idToken.claims.uuid);
                            getLoyaltyInfo(res.idToken.claims.uuid)
                        }).catch((err) => {
                            console.error(err.message);
                        })
                    }
                }
            )
        });
    },
    unmounted() {
        // Remove the widget from the DOM on path change
        oktaSignIn.remove();
    },
};
</script>

<style scoped>
img {
    margin-bottom: 25px;
}

.main {
    width: 100vw;
    height: 100vh;
    background: #004079;
    font-family: sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 315px;
}

form input,
form button {
    border: 1px solid #004079;
    border-radius: 5px;
    padding: 5px 10px;
    width: 240px;
    margin: 5px;
    font-size: 14px;
}

form button {
    background: #004079;
    color: #fff;
    cursor: pointer;
    text-align: center;
}

</style>
