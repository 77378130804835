import { oktaAuth } from '@/services/okta/config';

export default {
  /*
  * Logout
   */
  async logout(state, redirectUri) {
    state.loyaltyportal.user.isReady = false;
    localStorage.removeItem('user_token');
    localStorage.removeItem('user_id');
    localStorage.removeItem('accessToken_cultura');
    localStorage.removeItem('uuidCultura');
    localStorage.removeItem('last_name');
    localStorage.removeItem('first_name');
    await oktaAuth.signOut({
      postLogoutRedirectUri: redirectUri || (
        (document.referrer && document.referrer !== `${window.location.origin}/`) ? document.referrer : 'https://aem.one-recette.cultura.com'
      ),
    });
  },
};
