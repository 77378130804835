import axios from 'axios';

// Get token from localStorage
const getToken = (withBearer = true) => (withBearer ? 'Bearer ' : '') + localStorage.getItem('user_token');
// Create an axios instance
const LP = axios.create({

  baseURL: process.env.VUE_APP_AXIOS_BASEURL_DJANGOAPI,
  headers: {
    Authorization: getToken(),
  },
});

// Add a request interceptor
LP.interceptors.request.use((config) => {
  config.headers.Authorization = getToken();
  return config;
}, (error) =>
  // Do something with request error
  Promise.reject(error));

// Add a response interceptor
LP.interceptors.response.use(
  (response) =>
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
    response.data,
  (error) =>
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
    Promise.reject(error),
);

// Export axios instance
export default LP;
