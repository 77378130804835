import store from '@/store';
import LP from '@/services/axios/loyaltyportal';

/**
 * Formate a number  using local or french format
 * @param {integer} nb the number to format
 * @returns {string} formatted number
 */
const formatNumber = (nb, local = true) => {
  if (nb !== undefined && nb !== null) {
    const split = nb.toString().split('.');
    if (split.length > 0 && parseInt(split[1], 10) !== 0) {
      split[0] = new Intl.NumberFormat('fr-FR').format(split[0]);
      return split.join(',');
    }

    if (local) {
      return parseInt(nb, 10).toLocaleString();
    }
    return new Intl.NumberFormat('fr-FR').format(nb);
  }

  return '0';
};

function closeHandler(e) {
  if (e.target.classList.contains('modalContent') || e.target.classList.contains('modal_poppin_bottom')) {
    document.body.style.overflow = 'auto';
    store.state.modalActive.name = null;
  }
}

async function reorganizeData(earns, bills, events, burns) {
  bills.forEach((bill) => {
    let availablePoints = 0;
    let initialPoints = 0;
    let validityEndDate = '';
    let validityBeginDate = '';
    let storeTitle = '';
    let amount = 0;
    bill.bill_products.forEach((bill_product) => {
      amount += parseInt(bill_product?.amount);
      if (bill_product.associate_earns) {
        availablePoints += bill_product.associate_earns.available_points;
        initialPoints += bill_product.associate_earns.initial_points;
        validityEndDate = bill_product.associate_earns.validity_end_date;
        validityBeginDate = bill_product.associate_earns.validity_begin_date;
        storeTitle = bill_product.associate_earns.extra_info.title;
      }
    });
    bill.available_points = availablePoints;
    bill.initial_points = initialPoints;
    bill.validity_end_date = validityEndDate;
    bill.validity_begin_date = validityBeginDate;
    bill.amount = amount;
    if (bill.store_title === '' || bill.store_title === undefined) {
      LP.get(`loyalty_portal/store/${bill.store_id}/`).then((response) => {
        bill.store_title = response.title;
      });
    }
  });

  let event_rules = await LP.get('loyalty_portal/event-rule/', { params: { deleted: 'true' } });
  event_rules = event_rules.results;
  events.forEach((event) => {
    event.event_rule_associate = event_rules.find((e) => e.id === event.event_rule_id);
    const earn = earns.find((e) => e.event === event.id);
    event.validity_begin_date = earn.validity_begin_date;
    event.earn_type = earn.earn_type;
  });
}

/**
 * Get all the elements that have the class "sliderCtn" and add the "drag to scroll" feature to them.
 * Returns all scrollable elements
 */
function setDragToScroll(className = 'sliderCtn') {
  const scrollables = [];

  // get all scrollable slider
  const sliders = document.getElementsByClassName(className);

  // fill a new object array with additional information
  for (let i = 0; i < sliders.length; i += 1) {
    scrollables.push(
      {
        element: sliders[i], isDown: false, scrollLeft: null, startX: null,
      },
    );
  }

  // set drag to scroll for all slider
  for (let i = 0; i < scrollables.length; i += 1) {
    // events list
    const events = [
      ['pointerdown', 'pointerup', 'pointerleave', 'pointermove'],
      ['mousedown', 'mouseup', 'mouseleave', 'mousemove'],
    ];

    // set all events
    for (let b = 0; b < events.length; b += 1) {
      if (scrollables[i]) {
      // grab event
        scrollables[i].element.addEventListener(events[b][0], (e) => {
          scrollables[i].isDown = true;
          scrollables[i].startX = e.pageX - scrollables[i].element.offsetLeft;
          scrollables[i].scrollLeft = scrollables[i].element.scrollLeft;
          scrollables[i].element.classList.add('grab_active');
        });

        // finish grab event
        scrollables[i].element.addEventListener(events[b][1], () => {
          scrollables[i].isDown = false;
          scrollables[i].element.classList.remove('grab_active');
        });

        // leave grab event
        scrollables[i].element.addEventListener(events[b][2], () => {
          scrollables[i].isDown = false;
          scrollables[i].element.classList.remove('grab_active');
        });

        // move event
        scrollables[i].element.addEventListener(events[b][3], (e) => {
          if (!scrollables[i].isDown) return;
          e.preventDefault();
          const x = e.pageX - scrollables[i].element.offsetLeft;
          const walk = (x - scrollables[i].startX) * 3;
          scrollables[i].element.scrollLeft = scrollables[i].scrollLeft - walk;
        });
      }
    }
  }

  return scrollables;
}

export {
  formatNumber,
  closeHandler,
  reorganizeData,
  setDragToScroll,
};
