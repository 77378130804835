<script setup>
import { ref } from 'vue';
import { closeHandler } from '@/methods/utils';

const emitSelf = defineEmits(['declaredPurchase', 'close']);

const maxLength = ref(12);
const billNumber = ref('');

const declarePurchase = (event) => {
  event.preventDefault();

  emitSelf('declaredPurchase', true);
};

document.body.style.overflow = 'hidden';

function closing() {
  document.body.style.overflow = 'auto';
  emitSelf('close', false);
}
</script>

<template>
  <div
    class="modalContent"
    @click="closeHandler"
    @keyup.enter="declarePurchase"
  >
    <div class="popin_box popin_declaration">
      <h4>Achat oublié, points sauvés !</h4>
      <p class="txt">
        C’est bien parce que c’est vous ! Vous disposez d’un joker par an pour rattraper un oubli. Munissez-vous de votre ticket de caisse et récupérez les points correspondant à votre achat.
      </p>

      <div class="formCtn">
        <form @submit="declarePurchase">
          <label for="ticketNumber">Numéro de ticket</label>
          <input
            id="ticketNumber"
            v-model="billNumber"
            type="text"
            class="saisie"
            :maxlength="maxLength"
          >
          <span class="subTxt">Votre ticket comporte 12 chiffres</span>
          <input
            :disabled="billNumber.length !== 12"
            :class="{disabledBtn: billNumber.length !== 12}"
            type="submit"
            value="Valider ma déclaration"
            class="cta cta--tertiary"
          >
        </form>
      </div>

      <span
        class="closeBtn"
        @click="closing"
      />
    </div>
  </div>
</template>
