import { createRouter, createWebHistory } from 'vue-router';
import { LoginCallback } from '@okta/okta-vue';
import store from '@/store';
import TC_Wrapper from "vue-tag-commander";

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Authentication.vue'),
  },
  {
    path: '/login/callback',
    component: LoginCallback,
  },
  {
    path: '/',
    name: 'homepage',
    meta: {
      navTitle: 'Retour à l’accueil',
    },
    component: () => import('@/views/HomePage.vue'),
  },
  {
    path: '/la-boutique',
    name: 'advantages',
    meta: {
      navTitle: 'La boutique Cadeaux',
    },
    component: () => import('@/views/Shop.vue'),
  },
  {
    path: '/produit/:id-:title',
    name: 'advantage',
    component: () => import('@/views/Product.vue'),
  },
  {
    path: '/mes-avantages',
    name: 'my-advantages',
    meta: {
      navTitle: 'Mes Cadeaux',
    },
    component: () => import('@/views/MyAdvantages.vue'),
  },
  {
    path: '/mes-points',
    name: 'my-points',
    meta: {
      navTitle: 'Mon historique de points',
    },
    component: () => import('@/views/MyPoints.vue'),
  },
  {
    path: '/le-programme',
    name: 'the-program',
    meta: {
      navTitle: "Mon programme Cultur'addict",
    },
    beforeEnter() {
      window.open('https://www.cultura.com/programme-fidelite-culturaddict.html');
      return false;
    },
  },
  {
    path: '/faq',
    name: 'faq',
    meta: {
      navTitle: 'La FAQ',
    },
    async beforeEnter() {
      const wrapper = TC_Wrapper.getInstance();
      await wrapper.setTcVars({"page_cat1": "la faq", "page_name": "accueil la faq"})
      window.open('https://www.cultura.com/programme-fidelite-culturaddict/faq.html');
      return false;
    },
  },
];

if (store.state.isMobile) {
  // Add route to fidelity card
  routes.push({
    path: '/ma-carte',
    name: 'my-card',
    meta: {
      navTitle: 'Afficher ma carte',
    },
  });
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// router.beforeEach(navigationGuard)
router.beforeEach((to, _, next) => {
  // close all popin
  store.state.modalActive.name = null;
  // Scroll to top on route change
  window.scrollTo(0, 0);
  if (to.name === 'Login') {
    next({ path: '/' });
  } else {
    next();
  }
});

export default router;
